<template>
  <v-switch
    :input-value="promoFilter"
    inset
    dense
    @change="handlePromoFilter"
    class="pt-0 mt-0"
    hide-details
  >
    <template v-slot:label>
      <span :class="{ 'text-caption': $vuetify.breakpoint.mdAndDown }">{{
        $t("filter.promo")
      }}</span>
    </template></v-switch
  >
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["promoFilter"],
  methods: {
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    }
  }
};
</script>

<style></style>
