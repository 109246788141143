<template>
  <div class="product-grid mt-2 mt-sm-0 pb-4 pb-md-0">
    <h2 v-if="title" v-html="title"></h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        v-for="(product, index) in products"
        :key="product.productId"
      >
        <ProductCard
          v-bind:product="product"
          :position="initPosition + index"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProductCard from "./ProductCard.vue";
import ProductService from "~/service/productService";
export default {
  components: { ProductCard },
  name: "ProductGrid",
  props: {
    productList: { type: Array, required: false },
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true },

    initPosition: { type: Number, default: 0 }
  },

  data() {
    return {
      products: [],
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: `#product-list-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchProducts();
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    async fetchProducts() {
      if (this.productList) {
        this.products = this.productList;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit
        });
        this.products = response.products;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        this.products = response.products;
      }
    }
  }
};
</script>
